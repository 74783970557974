* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Roboto", serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
    "wdth" 100;
}

body {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    overflow-x: hidden;
    // background-color: #e0e5ec;
}

.no-scroll {
    overflow: hidden;
}